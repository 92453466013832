<template>
  <div>
    <div class="d-flex ">
      <b-col
        md="2"
      >
        <b-form-group
          label="Année universitaire"
        >
          <v-select
            v-model="academicYear"
            :clearable="false"
            label="academic_year"
            placeholder="Année universitaire"
            :options="academicYears"
            disabled
          />

        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group
          label="Semester"
        >
          <v-select
            v-model="semester"
            :clearable="false"
            placeholder="Semester"
            :options="semesters"
            @input="getSubjectDistribution"
          />

        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group
          label="Parcours"
        >
          <v-select
            v-model="parcour"
            :clearable="false"
            placeholder="parcour"
            label="title"
            :options="parcours"
            @input="getSubjectDistribution"
          />

        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group
          label="Groups"
        >
          <v-select
            v-model="groupe"
            :clearable="false"
            placeholder="groupe"
            label="group_code"
            :options="groups"
            @input="getSubjectDistribution"
          />

        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group
          label="Matiére"
        >
          <v-select
            v-model="subject"
            :clearable="false"
            placeholder="matiere"
            label="label"
            :options="subjects"
            @input="getSubjectDistribution"
          />

        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group
          label="Enseignant"
        >
          <v-select
            v-model="teacher"
            :clearable="false"
            placeholder="choisire enseignant"
            label="username"
            :options="teachers"
            @input="getSubjectDistribution"
          />

        </b-form-group>
      </b-col>

    </div>

    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="subjectsTeacher"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <b-avatar
            src="/assets/images/avatars/user.png"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.username }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <feather-icon
                :id="`invoice-row-${props.row.id}-edit-icon`"
                icon="MailIcon"
                class="cursor-pointer mr-1"
                size="16"
                @click="sendMail(props.row.email, props.row.nom)"
            />
            <feather-icon
              :id="`invoice-row-${props.row.id}-trash-icon`"
              icon="TrashIcon"
              class="text-danger cursor-pointer"
              size="16"
              @click="deleteRoom(props.row.id)"
            />
            <b-tooltip
              title="Supprimer"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-trash-icon`"
            />

          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormSelect,
  BSpinner, BTooltip, BCol,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeAuth from '@/store/store'
import vSelect from 'vue-select'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormSelect,
    BSpinner,
    BTooltip,
    BCol,
    vSelect,
  },
  data() {
    return {
      load: 'false',
      pageLength: 10,
      user: {},
      semester: '',
      semesters: ['semester 1', 'semester 2'],
      columns: [
        {
          label: 'Enseignant',
          field: 'teacher.username',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Téléphone',
          field: 'teacher.phone',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Email',
          field: 'teacher.email',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },

        {
          label: 'Groupe',
          field: 'groupe.group_code',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },

        {
          label: 'Matière',
          field: 'subject.label',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'VH par Semaine',
          field: 'subject.load_week',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Charge Totale par Semaine',
          field: 'total_volume',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },

        {
          label: 'Date création',
          field: 'created_at',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher date',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      subjectsTeacher: [],
      subjects: [],
      subject: '',
      searchTerm: '',
      academicYear: {},
      academicYears: [],
      currentAcademicYear: {},
      groups: [],
      parcours: [],
      parcour: '',
      groupe: '',
      unity: '',
      unites: [],
      teachers: [],
      teacher: '',

      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
      statuss: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  computed: {
    active() {
      const statusColor = {
        true: 'light-success',
        false: 'light-danger',
      }

      return status => statusColor[status]
    },

  },
  created() {
    this.getSubjectDistribution()
    this.getAcademicYears()
    this.getParcours()
    this.getGroups()
    this.getSubjects()
    this.getTeachers()
    this.user = storeAuth.state.user
    this.currentAcademicYear = storeAuth.state.currentAcademicYear
    this.academicYear = storeAuth.state.currentAcademicYear
  },
  methods: {
    sendMail(email, username) {
      axios.post('/api/subjects/email/', { message: "Prière de remplir le formulaire d'admission. afin de réserver votre place à MIT", username, email }).then(() => {
        setTimeout(() => {
          this.showToast('success', 'top-center', 'Email envoyée avec succés')
        }, 1000)
      })
    },
    async getSubjectDistribution() {
      this.load = 'true'
      const response = await axios.get('/api/subjects/subject_distribution/', {
        params: {
          academic_year_id: this.currentAcademicYear.id,
          parcour_id: this.parcour.id,
          teacher_id: this.teacher.id,
          groupe_id: this.groupe.id,
          subject_id: this.subject.id,
          semester: this.semester.id,

        },
      })
      this.subjectsTeacher = response.data
      this.load = 'false'
    },
    async getAcademicYears() {
      const response = await axios.get('api/academic-years/')
      this.academicYears = response.data
    },
    async getParcours() {
      const response = await axios.get('/api/parcours/')
      this.parcours = response.data
    },
    async getGroups() {
      this.load = 'true'
      const response = await axios.get('/api/groups/')
      this.groups = response.data
      // this.semaines = response.data
      this.load = 'false'
    },
    async getSubjects() {
      this.load = 'true'
      const response = await axios.get('/api/subjects/')
      this.subjects = response.data

      this.load = 'false'
    },
    async getTeachers() {
      this.load = 'true'
      const response = await axios.get('api/authentication/teachers/')
      this.teachers = response.data
      this.load = 'false'
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(
        2,
        '0',
      )} | ${h}`
    },
    deleteRoom(id) {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux supprimer ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.load = 'true'
        if (result.value) {
          axios
            .delete(`/api/rooms/delete/${id}/`)
            .then(() => {
              this.load = 'false'
              this.getRooms()
              setTimeout(() => {
                this.showToast('success', 'top-center', 'Salle supprimée avec succés')
              }, 1000)
            })
            .catch(error => {
              this.load = 'false'
              setTimeout(() => {
                this.showToast('danger', 'top-center', error.toString())
              }, 1000)
            })
        } else if (result.dismiss === 'cancel') {
          this.load = 'false'
          this.$swal({
            title: 'Fermer',
            text: 'Salle Non Supprimée :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
